.smtm {
    color: #000000;
}

.project__background--smtm {
    background: url("../images/money.png");
    background-color: #00be4f;
    background-repeat: repeat;
    background-size: 180px;
}

.project__background--smtm-gradient {
    /* background: linear-gradient(#00be4fbe 0%, #00be4f4f 50%, #00be4fbe 100%); */
    background: linear-gradient(90deg, #00be4f33 0%, #00be4fdd 33.33%, #00be4f 50%, #00be4fdd 66.66%, #00be4f33 100%);
}

h1.smtm-title {
    color: #FFFFFF;
    text-align: center;
    font-family: 'Playfair Display';
    padding: 0;
    font-size: 48px;
    font-weight: 700;
    text-shadow: 0px 4px 4px #2d2e3355;
}

.smtm-title__wrapper {
    /* background: radial-gradient(#00be4f 15%, #00000000 100%); */
    /* background: linear-gradient( #00be4f 66.66%, #00000000 100%); */
    padding: 100px 0;
    height: 100%;
    width: 100%;
}

.smtm__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}

.smtm__section {
    margin-bottom: 40px;
}

.project-card__outer--smtm {
    background-color: #FFFFFF;
    border-radius: 16px;
    box-shadow: 0px 4px 4px #2d2e3355;
}

.project-card--smtm {
    padding: 32px;
}

h3.smtm-card__title {
    margin: 0 0 12px 0;
    font-size: 24px;
}

.smtm-button {
    background-color: #00be4f;
    border-radius: 28px;
    border: none;
    padding: 12px 32px;
    margin: 16px;
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    box-shadow: 0px 4px 4px #2d2e3355;
}

.smtm-button__group {
    margin: 16px 0 0 0;
}

.contact--smtm {
    max-width: 752px;
}

.smtm__image-grid {
    display: flex;
    max-width: 800px;
    gap: 24px
}

.smtm__image {
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    margin: 6px;
}

.smtm__image-wrapper {
    border-radius: 16px;
    box-shadow: 0px 4px 4px #2d2e3355;
    max-width: 400px;
    width: calc(50% - 12px);
    background-color: #f2f2f2;
}

@media screen and (max-width: 564px) {
    .smtm-button {
        width: calc(100% - 32px);
    }
    .smtm__image-grid {
        flex-direction: column;
    }
    .smtm__image-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .project-card--smtm {
        padding: 16px;
    }
}