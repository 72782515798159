@font-face {
  font-family: 'Kiss Me or Not';
  font-style: normal;
  font-weight: normal;
  src: url('../fonts/kiss-me-or-not.woff') format('woff');
}

body {
  color: #FFFFFF;
  background-size: cover;
  font-family: 'Open Sans', sans-serif;
  margin: 0;
  background-color: #1E1432;
}

/* The main title */

h1 {
  font-family: 'Kiss Me or Not';
  font-size: 120px;
  font-weight: 400;
  color: #FFD700;
  margin: 0;
}

/* The main subtitle */

h2 {
  font-size: 28px;
  text-transform: uppercase;
}

h2.hero__subtitle {
  margin: 0 20px;
}

h1, h2.hero__subtitle {
  text-shadow: 0px 0px 8px #00000080, 0px 0px 4px #00000040, 0px 0px 2px #00000040;
  z-index: -1;
}

/* Section headers */

h3 {
  font-size: 28px;
  margin: 0 0 20px 0;
}

h4, p {
  margin: 0;
}

.add-break {
  margin-bottom: 12px;
}

a {
  color: #FFFFFF;
  text-decoration: none;
}

.hero {
  height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

#starfield {
  width: 100%;
  height: 100%;
}

.starfield__wrapper {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: -2;
  background: #1E1432 url('../images/stars.jpg') no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.section {
  margin: 80px 20px 98px 20px;
  width: 100%;
  max-width: 840px;
}

.section__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 20px
}

.section__wrapper--dark {
  background-color: #000000;
}

.section__wrapper--blur {
  backdrop-filter: blur(8px) brightness(2);;
  -webkit-backdrop-filter: blur(8px) brightness(2);;

}

.projects__grid {
  display: grid;
  grid-auto-columns: calc(calc(100% / 3) - calc(40px / 3));
  grid-auto-flow: column;
  overflow-x: scroll;
  grid-template-rows: 1fr;
  gap: 0px 20px;
}

.projects__grid--major {
  margin-bottom: 60px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */

.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */

.scrollbar-hidden {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.project-poster {
  background-color: #29292C;
  border-radius: 12px;
  width: 100%;
  height: 100%;
}

.project-poster__image {
  width: 100%;
  border-radius: 12px 12px 0 0;
  box-shadow: -2px 2px 2px #00000040;
}

.project-poster__info {
  padding: 8px;
}

.tech-list {
  display: flex;
  flex-wrap: wrap;
  padding: 8px 0 4px 0;
}

.tech-label {
  padding: 4px 12px;
  margin: 4px 8px 4px 0;
  background-color: #000000;
  border: 2px solid #FFFFFF;
  border-radius: 20px;
  box-shadow: -2px 2px 2px #00000040;
}

.grid__2-3 {
  display: grid;
  grid-template-columns: calc(calc(200% / 3) - calc(20px / 3)) calc(calc(100% / 3) - calc(10px / 3) - 10px);
  grid-template-rows: 1fr;
  gap: 0px 20px;
}

.grid__2-3--add-margin {
  margin-bottom: 80px;
}

.horizontal-grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0px;
}

.horizontal-grid--about {
  text-align: center;
  justify-content: space-around;
}

.about__title {
  width: calc(calc(200% / 3) - calc(20px / 3));
}

.about__icon {
  width: 72px;
}

.about__image {
  width: 100%;
  border-radius: 50%;
  /* margin-bottom: -100%; */
}

.about__fixed-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: center center;
  top: 0;
  left: 0;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  background: #1E1432 url('../images/stars.jpg') no-repeat fixed center;
  z-index: -3;
}

.contact {
  color: #FFFFFF;
}

.contact__method {
  display: flex;
  align-items: center;
  padding: 6px;
  margin: -6px;
  transition: 0.2s;
  border-radius: 8px;
}

a .contact__method:hover {
  box-shadow: 0 0 0 2px #FFFFFF;
}

.contact__method--icon {
  width: 32px;
  height: 32px;
  padding-right: 4px;
}

.foot-note {
  background-color: black;
  padding-bottom: 8px;
}

.foot-note__text {
  text-align: center;
  margin: auto;
  font-family: 'Kiss Me or Not', cursive;
  font-size: 20px;
  color: #4e0072;
}

.modal {
  padding: 20px;
  margin: 20px;
  border-radius: 12px;
  background-color: #29292C;
  text-align: center;
  box-shadow: -4px 2px 6px #00000060;
}

.modal__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal__button {
  margin: 24px auto 8px auto;
}

@media screen and (max-width: 780px) {
  .projects__grid {
    grid-auto-columns: calc(50% - 80px);
    margin: 0 -20px;
  }
  .projects__grid:after {
    content: '';
    width: 20px;
  }
}

@media screen and (max-width: 700px) {
  .grid__2-3 {
    grid-template-columns: 100%;
  }
  .about__image {
    margin: 80px auto 0 auto;
    width: 50%;
  }
  .about__title {
    width: 100%;
  }
  .horizontal-grid {
    flex-direction: column;
    margin: 0 auto;
  }
  .contact__method {
    margin: 4px 0;
  }
  .about__bullet {
    margin: 20px 0;
  }
  .contact__method--icon {
    padding-right: 8px;
  }
}

@media screen and (max-width: 564px) {
  h1 {
    font-size: 24vw;
  }
  h2.hero__subtitle {
    font-size: 6vw;
  }
  .about__image {
    width: 75%;
  }
  .projects__grid {
    grid-auto-columns: calc(100% - 120px);
  }
  .tech-label > p {
    font-size: 12px;
  }
}