.oww {
    color: #000000;
}

.project__background--oww {
    background: url(../images/oww-corridor.svg) no-repeat center;
}

.oww-logo {
    margin: -25% 15% 0 15%;
    height: 70%;
    width: 70%;
}

.project-card__outer--oww {
    background-color: #FFFFFF;
    box-shadow: 0px 4px 0px #CCCCCC;
    border-radius: 8px;
}

.project-card--oww {
    border-radius: 8px;
    border: 4px solid #EEEEEE;
    box-shadow: inset 0px 4px 0px #DDDDDD;
}

h3.oww-card__title {
    margin: 0 0 12px 0;
    font-size: 24px;
}

iframe {
    display: block;
    background: #000;
    border: none;
    aspect-ratio: 16 / 9;
    width: 100%;
}

.exposure-image {
    width: 100%;
}

.oww-image--end {
    margin-bottom: -5px;
}

.oww-image--break {
    margin-bottom: 8px;
}

.contact--oww {
    max-width: 752px;
}

@media screen and (max-width: 564px) {
    .oww-logo {
        margin: -25% -10% 0 -10%;
        height: 120%;
        width: 120%;
    }
}